import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reset, isSubmitting, submit } from 'redux-form';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import moment from 'moment';
import getSelfType from 'selectors/getSelfType';
import validate from 'validate.js';
import formatError from 'helpers/formatError';
import { USER_TYPE } from 'helpers';
import { get, isEmpty } from 'lodash';

import MaterialIcon from 'components/pure/MaterialIcon';
import { create, createBulk, edit, updateLoadWithPaymentRequest } from 'actions/resource/load';
import { cancelEdit, displayHistory, initiateAdd, displayCurrentLoad } from 'actions/post';
import { openModal, closeModal } from 'actions/ui';
import SubmissionError from '../../datatypes/error/SubmissionError';

import DebtorCarrierPaymentForm, { FORM_NAME as FORM_CARRIER_PAYMENT } from '../pure/form/DebtorCarrierPaymentForm';
import { createNonFactoredPaymentsRequest, createPaymentsRequest } from '../../actions/groups';
import { getBrokerGroupCarriers } from '../../actions/groups';
import docTitle from 'components/hoc/docTitle';
import ResourceBy from 'components/hoc/ResourceBy';
import LoadPostForm, { FORM_NAME, editValidateHandler, validateHandler } from 'components/pure/form/LoadPostForm';
import LoadPostTable from 'components/pure/LoadPostTable';
import isFactoringBroker from 'selectors/isFactoringBroker';
import Post from 'components/pure/Post';
import ProTip from 'components/container/ProTip';
import Resource from '../hoc/Resource';
import getSelfHOC from '../hoc/getSelf';
import segmentEvents from '../../helpers/segmentEvents';
import { NO_DEBTOR_ID } from '../../helpers';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 18,
    color: '#000000',
    textAlign: 'center',
  },
  icon: {
    color: '#498504',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    //fontSize: 16,
    color: '#979797',
  },
};

export const onSubmitPaymentRequest = async (fields, dispatch, load, props = {}, noLoad) => {
  try {
    let res = {};
    // if (get(fields, 'bill_to.credit_approved', '') === 'declined') {
    //   await new Promise((resolve, reject) => dispatch(openModal('warning', {
    //     message: 'Please note that this shipper/debtor is declined on credit. This transaction will be set to Non-Factored and you and the carrier will be paid once we receive payment for the invoice. ',
    //     customCloseModal: () => {
    //       dispatch(closeModal());
    //       resolve(true);
    //     },
    //   })));
    // }
    const paymentProfileId = get(fields, 'carrier.payment_profile.id', null);
    if (paymentProfileId) {
      const res = await dispatch(
        getBrokerGroupCarriers(get(props, 'factoringId'), {
          payment_profile_id: paymentProfileId
        })
      );
      if ((res.results || []).length) {
        const carrier = get(res, 'results[0]', {});
        if (
          carrier.payout_days !== fields.payout_days ||
          carrier.factoring_fee_split_rate !== fields.broker_rate
        ) {
          await new Promise((resolve, reject) =>
            dispatch(
              openModal('ConfirmationFRPaymentSpeed', {
                modalSize: 'small',
                defaultValue: {
                  payout_days: carrier.payout_days,
                  factoring_fee_split_rate: carrier.factoring_fee_split_rate
                },
                currentValue: {
                  payout_days: fields.payout_days,
                  factoring_fee_split_rate: fields.broker_rate
                },
                initialValue: {
                  payout_days: fields.payout_days,
                  factoring_fee_split_rate: fields.broker_rate
                },
                handleSubmit: () => {
                  dispatch(closeModal());
                  setTimeout(() => resolve(true), 200);
                },
                closeModal: () => {
                  dispatch(closeModal());
                  setTimeout(() => reject(true), 200);
                },
              })
            )
          );
        }
      }
    }
    if (fields.non_factored) {
      let data = {
        amount: fields.amount,
        payment_profile_amount: fields.carrier_amount,
        debtor: get(fields, 'bill_to.id', null),
        payment_profile_payment_method: fields.payment_method,
        carrier_pro_number: fields.carrier_pro_number,
        purchase_order_number: fields.purchase_order_number,
        line_items: fields.line_items,
        contract_type: fields.contract_type,
        user_load_number: fields.user_load_number,
        ...noLoad ? {
                        trailer_type: fields.load_trailer_type,
                        load_weight: fields.load_weight,
                        load_length: fields.load_length,
                        origin_locations: fields.origin_locations.map(({ street_one, city, state, zip, country, instructions }, i) => ({
                            name: fields.names_origin_location && fields.names_origin_location.length > i ? fields.names_origin_location[i] : '',
                            street_one: street_one,
                            city: city,
                            state: state,
                            zip: zip,
                            country: country
                        })),
                      destination_locations: fields.origin_locations.map(({ street_one, city, state, zip, country, instructions }, i) => ({
                        name: fields.names_origin_location && fields.names_origin_location.length > i ? fields.names_origin_location[i] : '',
                        street_one: street_one,
                        city: city,
                        state: state,
                        zip: zip,
                        country: country
                      })),
            }
            : { load_id: load }
      };
      if (fields.non_factored && !fields.bill_out_only) {
        data = {
          ...data,
          payment_profile: get(fields, 'carrier.payment_profile.id', null),
          non_factored_type: 'standard',
        };
      }
      if (fields.bill_out_only) {
        data = {
          ...data,
          non_factored_type: 'bill_out',
        };
      }
      res = await dispatch(createNonFactoredPaymentsRequest(data, noLoad && { no_load: noLoad }));
    }
    else {
      const data = {
        user_load_number: fields.user_load_number,
        apply_reserve_holdback: fields.apply_reserve_holdback,
        amount: fields.amount,
        debtor: get(fields, 'bill_to.id', NO_DEBTOR_ID),
        payment_profile_amount: fields.carrier_amount,
        load_length: fields.load_length,
        payment_profile_payment_method: fields.payment_method,
        payment_profile_id: paymentProfileId,
        factoring_amount: fields.amount - fields.carrier_amount,
        factoring_fee_split_rate: fields.broker_rate,
        payout_days: fields.payout_days === 30 ? 'ach_30_day' : fields.payout_days,
        pass_debtor_rate_to_carrier: fields.pass_debtor_rate_to_carrier,
        carrier_pro_number: fields.carrier_pro_number,
        purchase_order_number: fields.purchase_order_number,
        self_finance_requested: fields.self_finance_requested,
        line_items: fields.line_items,
        contract_type: fields.contract_type,
        ...noLoad ? {
                    trailer_type: fields.load_trailer_type,
                    load_weight: fields.load_weight,
                    load_length: fields.load_length,
                    origin_locations: fields.origin_locations.map(({ street_one, city, state, zip, country, instructions }, i) => ({
                            name: fields.names_origin_location && fields.names_origin_location.length > i ? fields.names_origin_location[i] : '',
                            street_one: street_one,
                            city: city,
                            state: state,
                            zip: zip,
                            country: country
                        })),
                    destination_locations: fields.origin_locations.map(({ street_one, city, state, zip, country, instructions }, i) => ({
                        name: fields.names_origin_location && fields.names_origin_location.length > i ? fields.names_origin_location[i] : '',
                        street_one: street_one,
                        city: city,
                        state: state,
                        zip: zip,
                        country: country
                      })) }
            : { user_load_number: fields.user_load_number, load_id: load }
      };
      res = await dispatch(createPaymentsRequest(data, noLoad && { no_load: noLoad }));
    }
    dispatch(updateLoadWithPaymentRequest(res));
    if (fields.non_factored) {
      await new Promise((resolve, reject) => setTimeout(dispatch(openModal('AddDocsNonFactored', {
        action: () => resolve(true),
        cancel: () => reject(false),
        requestId: res.id,
      }), 250)));
    }
    window.analytics.track(segmentEvents.BROKER_CREATED_FUNDING_REQUEST, res);
    dispatch(closeModal());
    dispatch(openModal('success', { message: 'Successfully made payment request.' }));
    return res;
  }
  catch (err) {
    console.log("ERR", err);
    dispatch(closeModal());
    if (get(err, 'user_load_number.[0]', '') === 'You have already submitted this load number.') {
      setTimeout(dispatch(openModal('error', {
        message: 'This load number already has a funding request associated with it. Please go to Payment History and edit your previous funding request.',
      })), 200);
      throw err;
    }
    if (get(err, 'user_load_number.[0]', '') === 'User load number or Load required') {
      setTimeout(dispatch(openModal('error', {
        message: 'Load Number is missing, Please make sure the load have a load number.',
      })), 200);
      throw err;
    }
    if (get(err, 'payment_profile.[0]', '') === 'The Carrier on the Funding Request has not yet been Approved by HaulPay. Please contact our team at support@haulpay.io to get the status of the Carrier\`s review process') {
      setTimeout(dispatch(openModal('error', {
        message: 'The Carrier on the Funding Request has not yet been Approved by HaulPay. Please contact our team at support@haulpay.io to get the status of the Carrier\`s review process.',
      })), 200);
      throw err;
    }
    if (get(err, 'payment_profile_payment_method', '') === 'Payment method does not exist. Please add.') {
      setTimeout(dispatch(openModal('error', {
        message: 'Payment method does not exist. Please add.',
      })), 200);
      throw err;
    }
    if (get(err, 'payment_profile_amount.[0]', '') === 'Amounts don\'t add up to Invoice Amount') {
      setTimeout(dispatch(openModal('error', {
        message: 'Amounts don\'t add up to Invoice Amount',
      })), 200);
      throw err;
    }
    if (get(err, 'factoring_amount.[0]', '') === 'Amounts don\'t add up to Invoice Amount') {
      setTimeout(dispatch(openModal('error', {
        message: 'Amounts don\'t add up to Invoice Amount',
      })), 200);
      throw err;
    }
    if (get(err, 'negative_margin_limit', '')) {
      setTimeout(dispatch(openModal('error', {
        message: get(err, 'negative_margin_limit', ''),
      })), 200);
      throw err;
    }
    if (err instanceof SubmissionError) {
      if (/You have already submitted this load number./.test(formatError(err))) {
        setTimeout(dispatch(openModal('warning', {
          message: 'This load number already has a funding request associated with it. Please go to Payment History and edit your previous funding request.',
        })), 200);
      }
      if (/Debtor has been declined./.get(err, ['message', 'non_field_errors', '0'])) {
        setTimeout(dispatch(openModal('error', {
          headerChildren: 'Customer Declined on Credit',
          message: 'Credit for this customer has recently been declined. We will check this customer\'s credit again in regular intervals but a payment request cannot be made for this customer at this time. Please email invoice@haulpay.io if you have more questions. Thank you.',
        })), 200);
      }
      if (/Payment method does not exist. Please add./.test(formatError(err))) {
        setTimeout(dispatch(openModal('warning', {
          message: 'Payment method not available. Please make sure the carrier have a payment method.',
        })), 200);
      }
      throw err;
    }
    setTimeout(dispatch(openModal('error', { message: 'Failed to send payment request.' })), 200);
    throw err;
  }
};

export const LoadPostResults = (history, props) => compose(
  connect(state => {
    const fetchArgs = props.pathname === '/postload/haulpay' ? { haulpay_loads: true } : {};
    return { id: state.user.id, fetchArgs };
  }),
  ResourceBy('user', history ? 'load' : 'loadhistory', { shouldClearBeforeFetch: true }),
  mapProps(({ item, actions, openLoadModalPost }) => ({
    ...item,
    openLoadModalPost,
    fetchNext: actions.fetchNext,
    ...props,
  })),
)(LoadPostTable);

const mapFieldsToData = (fields, isFactoringBroker) => ({
  origin_locations: fields.origin_location.map(({ street_one, city, state, zip, country, instructions }, i) => ({
    name: fields.names_origin_location && fields.names_origin_location.length > i ? fields.names_origin_location[i] : '',
    street_one: street_one,
    city: city,
    state: state,
    zip: zip,
    country: country,
    instructions
  })),
  destination_locations: fields.destination_location.map(({ street_one, city, state, zip, country, instructions }, i) => ({
    name: fields.names_destination_location && fields.names_destination_location.length > i ? fields.names_destination_location[i] : '',
    street_one: street_one,
    city: city,
    state: state,
    zip: zip,
    country: country,
    instructions
  })),
  trailerType: fields.trailerType.value || fields.trailerType,
  targetRate: Math.floor(fields.targetRate * 100) || undefined,
  weight: Number(fields.weight),
  length: Number(fields.length),

  time_start: moment(fields.time_start, 'l HH:mm').toDate(),
  time_end: moment(fields.time_end, 'l HH:mm').toDate(),
  time_delivery: moment(fields.time_delivery, 'l').endOf('day').toDate(),

  details: fields.details,
  post_to: isFactoringBroker ? 'all' : fields.post_to,
  groups_post_to: isFactoringBroker ? fields.groups_post_to : 'all',
  user_load_number: fields.user_load_number,
});

const validateUserType = handler => (userType, generateLoadNumber) => fields => {
  let errs = handler(fields);
  if ([USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(userType)) {
    errs = {
      ...errs,
      ...validate(fields, {
        targetRate: {
          presence: true,
          numericality: true,
        },
      }),
    };
  }

  if (generateLoadNumber) {
    delete errs.user_load_number;
  }

  return errs;
};

const validateCreate = validateUserType(validateHandler);
const validateEdit = validateUserType(editValidateHandler);

export const ValidationPopUpView = ({ closeModal, isNonFactored }) =>
  <div style={{ ...styles.flexRow, justifyContent: 'center', flexDirection: 'column' }}>
    <div
      style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
    >
      <MaterialIcon
        name='check_circle_outline'
        size={70}
        style={styles.icon}
      />
    </div>
    {isNonFactored ?
      <p style={styles.text}>
        An invoice, along with any docs attached visible to the shipper, will be sent to your customer when you upload of Signed Bill of Lading.
      </p>
      :
      <p style={styles.text}>
        Thank you for creating a pending carrier payment request with this load.
        Once the delivery is done the carrier needs to mark the load complete and/or upload final docs.
        Then you will need to approve the request.
      </p>
    }
    <div
      style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
    >
      <button className='btn btn-default' style={{ maxWidth: '25%' }} onClick={closeModal}>Ok</button>
    </div>
  </div>;
export const openFundingRequestPaymentModal = (payload, closeModal, openModal, onSubmitPaymentRequest, isSubmittingPayment, submitPaymentForm ) => openModal('EmptyModal', {
  children: <DebtorCarrierPaymentForm
    initialValues={{
      user_load_number: payload.user_load_number,
      carrier_amount: payload.targetRate,
      load_length: payload.length || 0,
      no_load: payload?.no_load,
      ...payload,
    }}
    assignLoad={Boolean(payload.user_load_number || payload?.no_load && payload?.generateLoadNumber)}
    noLoad={payload?.no_load}
    onSubmit={async (fields, dispatch, props) => {
      try {
        closeModal();
        const debtorAvailableCredit = get(fields, 'bill_to.credit_available', 0);
        const amount = get(fields, 'amount', 0);
        let res = true;
        if (debtorAvailableCredit <= amount && (!fields.non_factored && !fields.bill_out_only && !fields.self_finance_requested)) {
          res = await new Promise((resolve, reject) => setTimeout(openModal('DebtorAmountLimit', {
            action: () => resolve(true),
            cancel: () => reject(false),
            isApprovedDebtorsOutCredit: props.allowSelfFinanceApprovedDebtorsOutOfCredit && fields.contract_type === "STD_BROKER" && !props.allowSelfFinanceFrApprovedDebtor,
            updateFundingRequest: () => openFundingRequestPaymentModal(fields, closeModal, openModal, onSubmitPaymentRequest, isSubmittingPayment, submitPaymentForm)
          }), 250));
        }
        if (!res) return;
        await onSubmitPaymentRequest(fields, dispatch, payload.load_id, props, payload?.no_load);
        openModal('EmptyModal', {
          children: <ValidationPopUpView closeModal={closeModal} />,
        });
      }
      catch (e) {
        console.log("ERROR", e)
      }
    }}
  />,
  displayCloseIcon: false,
  control: <button className='btn btn-orange' onClick={() => submitPaymentForm(FORM_CARRIER_PAYMENT)} disabled={isSubmittingPayment}>Create</button>,
});

export default compose(
  docTitle('Post Load'),
  getSelfHOC,
  Resource('factoringclient', {
    idPropName: ['item', 'data', 'factoring_id'],
    resourcePropName: 'factoringclient',
  }),
  connect(
    state => ({
      userType: getSelfType(state),
      editId: state.post.load.editId,
      copyId: state.post.load.copyId,
      addModel: state.post.load.addStatus,
      userId: state.user.id,
      isSubmitting: state.post.load.editId && state.resource.load[state.post.load.editId] ?
        state.resource.load[state.post.load.editId].isFetching :
        state.resource.load.null ?
          state.resource.load.null.isFetching : false,
      isSubmittingPayment: isSubmitting(FORM_CARRIER_PAYMENT)(state),
      isFactoringBroker: isFactoringBroker(state),
    }),
    {
      submitHandler: create,
      bulkSubmitHandler: createBulk,
      editHandler: edit,
      cancelEdit: () => cancelEdit('load'),
      openModal,
      reset,
      closeModal,
      submitPaymentForm: submit,
      displayHistory,
      initiateAdd,
      displayCurrentLoad,
    },
  ),
  mapProps(({
    userType,
    userId,
    editId,
    copyId,
    cancelEdit,
    editHandler,
    submitHandler,
    bulkSubmitHandler,
    openModal,
    closeModal,
    reset,
    submitPaymentForm,
    isSubmittingPayment,
    isFactoringBroker,
    ...rest
  }) => {
    const generateLoadNumber = get(rest, ['factoringclient', 'data', 'auto_generate_load_numbers'], false);
    return ({
      editId,
      ...(() => editId === undefined ? {
        headerText: 'Post a Load',
        submitButtonText: 'Next >',
        cancelEdit: copyId === undefined ? undefined : cancelEdit,
        validator: validateCreate(userType, generateLoadNumber),
        submitHandler: async (fields, dispatch, { isFactoringBroker }) => {
          try {
            const data = mapFieldsToData(fields, isFactoringBroker);
            let promise;
            if (fields.count > 1) {
              const arr = [];
              for (let i = 0; i < fields.count; i++) {
                arr.push({ ...data });
              }
              promise = bulkSubmitHandler(arr);
            }
            else {
              promise = submitHandler(data);
            }
            const result = await promise;
            closeModal();
            if (!isFactoringBroker) {
              return dispatch(openModal('success', { message: 'Successfully post a load.' }));
            }
            window.analytics.track(segmentEvents.USER_POSTED_LOAD);
            const { payload = {} } = result;
            openModal('AssignCarrierInfoModal', {
              modalSize: 'small',
              action: {
                confirm: () => {
                  closeModal();
                  return openFundingRequestPaymentModal({
                        ...payload,
                        load_id: payload.id,
                      },
                      closeModal,
                      openModal,
                      onSubmitPaymentRequest,
                      isSubmittingPayment,
                      submitPaymentForm
                    );
                },
              },
              centered: true,
            });
            cancelEdit();
            reset(FORM_NAME);
          }
          catch (err) {
            closeModal();
            console.warn(err);
            openModal('error', { message: formatError(err, 'Error while posting load') });
          };
        },
        proTip: (
          <ProTip
            className='pull-right'
            name='Post Load'
            message={
              <div>
                <p>Be sure to include the commodity and a brief description of the freight that need to have hauled in the more details dialog box at the bottom of the Post Load form before posting. This will help bidders and carriers get a much better idea of what you actually need picked up.</p>
                <p>Also make sure the weight and length are accurate. If you have a target rate in mind for the move this also will help you get the right service provider bidding or contacting you on your load faster. Be sure to include any details about additional picks or drops in Details as well.</p>
              </div>
            }
          />
        ),
        uploadExcel: () => openModal('loaduploadexcel'),
      } : {
        headerText: 'Edit Load',
        submitButtonText: 'REPOST',
        cancelEdit: () => {
          cancelEdit();
          closeModal();
        },
        validator: validateEdit(userType),
        submitHandler: fields =>
          editHandler(editId, mapFieldsToData(fields, isFactoringBroker))
            .then(() => {
              closeModal();
              cancelEdit();
              openModal('success', { message: 'Successfuly edited load' });
              reset(FORM_NAME);
            })
            .catch(err => {
              console.warn(err);
              openModal('error', { message: formatError(err, 'Error while editing load') });
            }),
      })(),

      myResourceUrl: `/user/${userId}/load`,
      myResourceButtonText: 'My Posted Loads',
      resultsHeaderText: 'My Loads',
      Form: LoadPostForm,
      Results: LoadPostResults,
      openPostLoadModal: data => openModal('EmptyModal', data),
      // openPendingPayment: openModal('EmptyModal', {
      //   children: <DebtorCarrierPaymentForm
      //     onSubmit={fields => {
      //       closeModal();
      //       openModal('EmptyModal', {
      //         children: <ValidationPopUpView />,
      //       });
      //     }}
      //   />,
      //   title: 'Create a Pending Carrier Payment', headerStyle: { color: '#E86C00', marginBottom: '20' },
      //   control: <button className='btn btn-orange' onClick={() => submitPaymentForm(FORM_CARRIER_PAYMENT)} disabled={isSubmittingPayment}>Create</button>,
      // }),
      closeModal,
      ...rest,
    });
  }),
  withState('postLoadRef', 'setPostLoadRef', {}),
  withState('currentLoad', 'setCurrentLoad', true),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const { postLoadRef } = this.props;
      const postLoad = get(this.props, ['location', 'state', 'postLoad'], false)
      const action = get(this.props, ['location', 'action'])

      if (postLoadRef !== prevProps.postLoadRef && postLoad && action === 'PUSH') {
        postLoadRef.props.onClick();
      }
    },
  })
)(Post);
