import React from 'react';
import { Field } from 'redux-form';

import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import MaterialIcon from 'components/pure/MaterialIcon';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LoadInstructions from '../../LoadInstructions';


const MultiGoogleLocationInput = ({ fields, meta, limit = 5, instructions, displayName = true, ...rest }) =>
  <div>
    {fields.map((name, i) =>
      <div>
          {displayName &&
              <Field
                  key={i * 100 + 1}
                  name={`names_${name}`}
                  component={LabeledInput}
                  placeholder=''
                  type='text'
                  shouldError={meta => meta.touched && meta.error}
                  label={fields.length === 1 ? `${rest.label} Name` : `${rest.label} Name ${i + 1}`}
              />
          }
        <Field
          key={i}
          name={`${name}`}
          inputProps={{
            name
          }}
          component={GoogleLocationInput}
          shouldError={meta => meta.touched && meta.error}
          {...rest}
          label={fields.length === 1 ? `${rest.label} ` : `${rest.label}  ${i + 1}`}
        />
        {instructions &&
          <LoadInstructions key={i} name={name} />
        }
      </div>
    )}
    <div
      className='text-right'
      style={{
        marginTop: -5,
      }}
      >
      {
        fields.length > 1 ?
          <MaterialIcon name='remove_circle' role='button' size={20} onClick={() => fields.pop()} />
          : null
      }
      {
        fields.length < limit ?
          <MaterialIcon name='add_circle' role='button' size={20} onClick={() => fields.push({})} />
          : null
      }
    </div>
  </div>
  ;

export default MultiGoogleLocationInput;
