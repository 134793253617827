
const USER_FACTORING_STATUS = {
  NEW: 'new',
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED_3_MONTHS: 'declined_3_months',
  DECLINED_6_MONTHS: 'declined_6_months',
  DECLINED: 'declined',
  NEEDS_REVIEW: 'needs_review',
  DOCUMENT_REVIEW: 'document_review',
  DOCUMENT_ISSUE: 'document_issue',
  NOT_AUTHORIZED: 'not_authorized',
  BAD_LEAD: 'bad_lead',
  LEAD_IN: 'lead_in',
  DEAD: 'dead',
  PENDING_DELIVERY: 'pending_delivery',
  REMOTE_APPROVED: 'remote_approved',
  INCOMPLETE: 'incomplete',
  SPECIAL_PENDING: 'special_pending',
  INVOICE_PAID: 'invoice_paid',
  CARRIER_PAID: 'carrier_paid',
  NON_FACTORED_CARRIER: 'non_factored_carrier',
  NON_FACTORED: 'non_factored',
  DO_NOT_CONTACT: "do_not_contact",
  HOLD_REVIEW: "hold"
};

export default USER_FACTORING_STATUS;
