/*global API */
import qs from 'querystring';
import SubmissionError from 'datatypes/error/SubmissionError';

let controller = null;

export const getGroupInvited = (id, filters) => async (dispatch, getState) => {
  const res = await global.fetch(
    `${API.host}/groups/${id}/invited/?${qs.stringify(filters)}`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`
      }
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const getBrokerGroupCarriers = (factoring_id, filters) => async (
  dispatch,
  getState
) => {
  if (controller) controller.abort();
  controller = new AbortController();
  const signal = controller.signal;
  const res = await global.fetch(
    `${API.host}/factoring/${factoring_id}/payment_profiles/?${qs.stringify(
      filters
    )}`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`
      },
      signal
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const getNextBrokerGroupCarriers = url => async (dispatch, getState) => {
  const res = await global.fetch(url, {
    headers: {
      authorization: `bearer ${getState().user.token}`
    }
  });
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};
export const updatePaymentsProfile = (
  factoring_id,
  relationship_id,
  body,
  params
) => async (dispatch, getState) => {
  const res = await global.fetch(
    `${API.host}/factoring/${factoring_id}/payment_profiles/${relationship_id}/?${qs.stringify(
      params
    )}`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
      method: 'PATCH',
      body: JSON.stringify(body)
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const createPaymentsRequest = (body, params = {}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/funding/request/?${qs.stringify(
      params
    )}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body)
  });
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const createNonFactoredPaymentsRequest = (body, params) => async (
  dispatch,
  getState
) => {
  const res = await global.fetch(
    `${API.host}/factoring/funding/request/non_factored/?${qs.stringify(
      params
    )}`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const checkExistingPaymentProfile = (mc, dot, filters) => async (
  dispatch,
  getState
) => {
  const res = await global.fetch(
    `${
      API.host
    }/factoring/payment_profiles/check_exists/?mc=${mc}&dot=${dot}&${qs.stringify(
      filters
    )}`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
      method: 'GET'
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const getPaymentProfile = filters => async (dispatch, getState) => {
  const res = await global.fetch(
    `${API.host}/factoring/payment_profiles/all/?${qs.stringify(filters)}`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
      method: 'GET'
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const fetchFactorCompany = filters => async (dispatch, getState) => {
  const res = await global.fetch(
    `${API.host}/factoring/company/?${qs.stringify(filters)}`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
      method: 'GET'
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const getNextInvitedCarriers = url => async (dispatch, getState) => {
  const res = await global.fetch(url, {
    headers: {
      authorization: `bearer ${getState().user.token}`
    }
  });
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const createFactorCompanyRelation = (
  companyId,
  brokerClient,
  paymentProfileId,
  data
) => async (dispatch, getState) => {
  const res = await global.fetch(
    `${API.host}/factoring/company/${companyId}/relations/`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        ...data,
        broker_client: brokerClient,
        payment_profile: paymentProfileId
      })
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const sendFactorCompanyInvite = (
  companyId,
  paymentProfile
) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/company/${companyId}/send_invite/`, {
    headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
    method: "POST",
    body: paymentProfile ? JSON.stringify({ payment_profile: paymentProfile }) : {}
  });

  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};

export const createPaymentsProfiles = body => async (dispatch, getState) => {
  const res = await global.fetch(
    `${API.host}/factoring/${body.factoring_id}/payment_profiles/`,
    {
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    }
  );
  const json = await res.json();
  if (res.ok) {
    return json;
  }
  throw json;
};
