/* global EMPTY_PLACEHOLDER */

import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import withPropsOnChange from 'recompose/withPropsOnChange';
import shallowEqual from 'recompose/shallowEqual';
import ResourceBy from 'components/hoc/ResourceBy';
import nothingIf from 'components/hoc/nothingIf';
import ResourceByIDs from '../../../hoc/ResourceByIDs';
import ResponsiveTable from '../../../pure/ResponsiveTable';
import cs from 'classnames';
import { Link } from 'react-router-v4';
import { isEmpty, find } from 'lodash';
import formatPennies from 'helpers/formatPennies';
import createTableItems from 'helpers/createTableItems';
import { reduxForm } from 'redux-form';
import map from 'lodash/map';
import get from 'lodash/get';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import moment from 'moment';
import MaterialIcon from 'components/pure/MaterialIcon';
import { NOAStatus } from '../../FactoringCreditCheck';
import Spinner from 'components/pure/Spinner';
import Tooltip from 'components/pure/Tooltip';
import SelfFinanceIcon from 'components/pure/SelfFinanceIcon';
import colors from 'styles/colors.json';
import { mapProps } from 'recompose';
import ImageIcon from 'components/pure/ImageIcon';

const BROKER_ITEM_ID_SELECTOR = 'data.id';

const FUNDING_REQUEST_TYPE_MAPPING = {
    STD_BROKER: 'Factored',
    NON_FACTORED_STANDARD: 'Non-Factored Pay Carrier',
    NON_FACTORED_BILL_OUT: 'Non-Factored Bill Out Only',
    SELF_FINANCE_NO_INVOICE: 'Self-Financed No Invoicing',
    SELF_FINANCE_WITH_INVOICE: 'Self-Financed With Invoicing',
}

const checkStatus = status => {
  switch (status) {
    case USER_FACTORING_STATUS.PENDING_DELIVERY:
      return { text: 'Incomplete', value: status };
    case USER_FACTORING_STATUS.REMOTE_APPROVED:
      return { text: 'Approved', value: status };
    case USER_FACTORING_STATUS.INCOMPLETE:
      return { text: 'pending_delivery', value: 'pending_delivery' };
    case USER_FACTORING_STATUS.SPECIAL_PENDING:
      return { text: 'Pending', value: USER_FACTORING_STATUS.SPECIAL_PENDING };
    case USER_FACTORING_STATUS.PENDING:
      return { text: 'Pending', value: USER_FACTORING_STATUS.SPECIAL_PENDING };
    case USER_FACTORING_STATUS.CARRIER_PAID:
      return { text: 'Carrier Paid', value: USER_FACTORING_STATUS.CARRIER_PAID };
    case USER_FACTORING_STATUS.INVOICE_PAID:
      return { text: 'Invoice Paid', value: USER_FACTORING_STATUS.INVOICE_PAID };
    case USER_FACTORING_STATUS.HOLD_REVIEW:
      return { text: 'Hold-Review', value: 'hold' };
    default:
      return { text: status, value: status };
  }
};

const onItemSelect = (items = [], setSelectedItems) => {
  const filterdData = map(items, object => {
    const { data = {} } = object;
    return data.id;
  });
  setSelectedItems(filterdData);
};

const nonFactoredValueMap = {
  bill_out: 'Bill Out Only',
  standard: 'Pay Carrier',
};
const StatusMapping = {
  'approved': {
    color: colors.GREEN,
    text: 'Approved'
  },
  'pending': {
    color: colors.ORANGE,
    text: 'Pending'
  },
  'needs_review': {
    color: colors.YELLOW,
    text: 'Needs Review'
  },
  'declined': {
    color: colors.RED,
    text: 'Declined'
  },
};
const PaymentProfileStatus = ({ status }) => {
  if(!status) {
    return <div />;
  }
  return (
    <div>
      <Tooltip placement="left" text={StatusMapping[status].text}>
        <MaterialIcon name='fiber_manual_record' style={{ fontSize: 15, color: StatusMapping[status].color, marginLeft: 5 }} />
      </Tooltip>
    </div>
)}

const PaymentsTableComponents = (errors, useNewNFRequest) => createTableItems(
  ({ data, bulkError }) => {
    const contractType = get(data, 'contract_type');
    const status = get(data, 'status', '');
    const debtorDiscountRate = get(data, 'factoring_debtor_relation.discount_rate', undefined);
    const invoiceSent = [<p>Invoice Sent On:</p>, data.last_invoice_sent_time.map(date => <p>{moment(date).format('l LTS')}</p>)];
    return [
      <div style={{ display: 'inline-flex' }}>
        <PaymentProfileStatus status={get(data, 'payment_profile.status', undefined)} />
        {get(data, 'payment_profile.company_name', EMPTY_PLACEHOLDER)}
      </div>,
      data.debtor.dba ? data.debtor.dba : data.debtor_company_name,
      <NOAStatus
        data={{
          ...data.factoring_debtor_relation,
          id: data.debtor.id,
        }}
      />,
      <p style={{ display: 'flex', alignItems: 'center' }}>
        {formatPennies(data.amount)}
        <SelfFinanceIcon contractType={contractType} />
      </p>,
      data.invoice_number,
      data.invoice_date ?
        <div>
          <Tooltip text={invoiceSent}>
            <p className='text-success'>YES</p>
          </Tooltip>
        </div>
        : <p className='text-danger'>NO</p>,
      data.user_load_number || EMPTY_PLACEHOLDER,
      data.created.format('l LTS'),
      debtorDiscountRate ? `${debtorDiscountRate}%` : EMPTY_PLACEHOLDER,
      `${((data.discount_rate * (100 - data.factoring_fee_split_rate) / 100))}%` || EMPTY_PLACEHOLDER,
      data.payment_profile_amount ? formatPennies(data.payment_profile_amount) : EMPTY_PLACEHOLDER,
      data.amount_funded ? formatPennies(data.amount_funded) : EMPTY_PLACEHOLDER,
      ...(useNewNFRequest ? [FUNDING_REQUEST_TYPE_MAPPING[data.contract_type]]: []),
      <div>
        {
          data.attachments.length > 0 &&
          data.attachments[data.attachments.length - 1].after_issue === true &&
          <div className='text-success'>
            <MaterialIcon name='attach_file' />New!
            <br />({moment(data.attachments[data.attachments.length - 1].uploaded).format('MM/DD, hh:mm a')})
          </div>
        }
        {' '}
        <b
          className={cs('text-uppercase', {
            'text-success': ['approved', 'paid', 'remote_approved', 'invoice_paid', 'carrier_paid'].includes(status),
            'text-warning': ['pending', 'special_pending', 'fuel_advance', 'hold'].includes(status),
            'text-red': ['declined', 'incomplete', 'pending_delivery', 'document_issue'].includes(status),
            'text-blue': ['new'].includes(status),
          })}
        >{checkStatus(status).text}</b>
        {data.non_factored_type &&
          <p>{nonFactoredValueMap[data.non_factored_type]}</p>
        }
        {!isEmpty(data.attachments) && <Tooltip placement="top" text={
          <div id="tooltip-attachments-category">{(data.attachments || []).map(object => object.category || object.failed || object.uploaded ?
            <div>
              <span>
                {`${object.category}`}
              </span>
              <p className={object.failed === true && 'text-danger'}>
                {`${object.uploaded ? moment(object.uploaded).format('MM/DD, hh:mm a') : ''}
                ${object.failed === true ? ' failed' : ''}`}
              </p>
            </div> : null)}
          </div>}>
          <MaterialIcon id="attachments-added" name='attachments' size="30" style={{ color: colors.DARK_GREEN }} /></Tooltip>}
      </div>,
      <div>
        <Link to={`/haul-pay/${data.id}/transaction/`}><button className='btn btn-orange' type='button'>More</button></Link>
        {bulkError &&
          <Tooltip placement="left" text={bulkError.errorMessage}><MaterialIcon name='warning' style={{ fontSize: 35, color: colors.RED, marginLeft: 5 }} /></Tooltip>
        }
      </div>
    ];
  }, mapProps(props => {
    const bulkError = find(errors, ['fundingRequestId', props.data.id])
    return {
      style: {
        backgroundColor: bulkError ? colors.LIGHT_RED : 'transparent'
      },
      bulkError,
      ...props
  }}));

const PaymentsTableBroker =
  reduxForm({
    form: 'FactoringPaymentsBroker',
    destroyOnUnmount: false,
  })(({ fetchArgs, errors, actions, item, items, selectedItems, setSelectedItems, useNewNFRequest, ...props }) =>
    <form style={{ paddingTop: 20 }}>
      <ResponsiveTable
        containerProps={{
          className: 'col-fill',
        }}
        items={items}
        isFetching={item.isFetching}
        placeholder='No transactions.'
        headers={['Carrier', 'Debtor', 'NOA Status', 'Invoice Amnt.', 'Invoice #', 'Invoice Sent', 'Load #', 'Requested', 'Debtor Rate', 'Carrier %', 'Carrier Pay', 'Amnt. Funded.',...(useNewNFRequest ? ['Type']: []), 'Status', 'More']}
        TableItem={PaymentsTableComponents(errors, useNewNFRequest).TableItem}
        BlockTableItem={PaymentsTableComponents(errors, useNewNFRequest).BlockTableItem}
        selectable={true}
        selected={selectedItems}
        idSelector={BROKER_ITEM_ID_SELECTOR}
        onItemsSelect={items => onItemSelect(items, setSelectedItems)}
        errors={errors}
        fetchNext={actions.fetchNext}
      />
      {
        item.next ?
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {
              item.isFetching ?
                <Spinner />
                :
                <button type='button' className='btn btn-orange' onClick={actions.fetchNext} disabled={items.isFetching}>Load More</button>
            }
          </div>
          : null
      }
    </form>
  );

export default compose(
  connect(
    state => ({
        id: state.user.id,
        useNewNFRequest: get(state, `user.factoring.data.use_new_non_factored_funding_requests`, false),
    }),
    {},
  ),
  withPropsOnChange((props, nextProps) => !shallowEqual(props, nextProps),
    ({ errors, id, debtorID, path = null, setSelectedItems, isHistoryView, filter, isSearch }) => ({
    id,
    fetchArgs: {
      ...filter,
      debtor_id: debtorID,
      ...(() => (!isHistoryView && !isSearch) ? { status: checkStatus(path).value, load_or_invoice_or_debtor_or_status: '' } : {})(),
      assigned_load: true,
    },
    setSelectedItems,
    errors,
  })),
  ResourceBy('user', 'factoringpayment', {
    shouldClearBeforeFetch: true,
    abortRequest: true
  }),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringpayment',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching
        || props.errors.length !== nextProps.errors.length;
    },
  }))(PaymentsTableBroker);
