import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import RouteTabs from 'components/pure/RouteTabs';
import { head, get } from 'lodash';
import { withState, lifecycle, shouldUpdate, shallowEqual, withHandlers, withReducer } from 'recompose';
import { fetchFactoringProfile } from 'actions/user';
import PaymentsTableBroker from './FactoringPayments/FactoringBrokerPayments/PaymentTableBroker';
import TransactionHeader from './FactoringPayments/FactoringBrokerPayments/TransactionHeader';
import isDispatchBroker from 'selectors/isDispatchBroker';
import FactoringDispatcherBroker from './FactoringPayments/FactoringBrokerPayments/FactoringDispatcherBroker';
import CenteredColumn from '../pure/CenteredColumn';

const DebtorTransactionContainer = ({ enableHoldReviewFundingRequestStatus, componentkey, errors, setErrors, selectedItems, setSelectedItems, isDispatchBroker, setFilter, filter, useNewNFRequest, ...props }) => {
  const path = head(props.location.pathname.split('/').reverse());
  const base_path = '/haul-pay';
  const debtorID = get(props, 'params.id', null);
  const isHistoryView = Boolean(new RegExp('\\bhistory/customer\\b').test(props.location.pathname));
  const isSearch = Boolean(new RegExp('\\bsearch\\b').test(props.location.pathname));
  const routes = isDispatchBroker ? [
    [`${base_path}/transaction/pending`, 'Pending'],
    [`${base_path}/transaction/document_issue`, 'Doc Issue'],
    [`${base_path}/transaction/incomplete`, 'Incomplete'],
    [`${base_path}/transaction/remote_approved`, 'Approved'],
    [`${base_path}/transaction/declined`, 'Declined'],
    [`${base_path}/transaction/paid`, 'Paid'],
  ] : [
    [`${base_path}/transaction/incomplete`, 'Incomplete'],
    [`${base_path}/transaction/pending`, 'Pending'],
    ...(() => enableHoldReviewFundingRequestStatus ? [[`${base_path}/transaction/hold`, 'Hold']] : [])(),
    ...(() => useNewNFRequest ? [] : [[`${base_path}/transaction/non_factored`, 'Non Factored']])(),
    [`${base_path}/transaction/document_issue`, 'Doc Issue'],
    [`${base_path}/transaction/fuel_advance`, 'Fuel Advance'],
    [`${base_path}/transaction/remote_approved`, 'Approved'],
    [`${base_path}/transaction/declined`, 'Declined'],
    [`${base_path}/transaction/carrier_paid`, 'Carrier Paid'],
    [`${base_path}/transaction/invoice_paid`, 'Invoice Paid'],
  ]
  return (
    <div >
      <TransactionHeader
        selectedItems={selectedItems}
        setFilter={setFilter}
        errors={errors}
        setErrors={setErrors}
        path={path}
      />
      {
        !debtorID &&
        <RouteTabs
          style={{ flexGrow: 0 }}
          routes={routes}
        />
      }
      {
        isDispatchBroker ?
          <FactoringDispatcherBroker
            path={path}
            isDispatchBroker={isDispatchBroker}
            setSelectedItems={setSelectedItems}
            debtorID={debtorID}
            isHistoryView={isHistoryView}
            filter={filter}
            isSearch={isSearch}
            {...props}
          />
          :
          <CenteredColumn
            xs={12}
            style={{ height: '100vh' }}
            columnProps={{
              className: 'col-fill',
            }}
            >
            <PaymentsTableBroker
              path={path}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              debtorID={debtorID}
              isHistoryView={isHistoryView}
              filter={filter}
              isSearch={isSearch}
              errors={errors}
              key={componentkey}
            />
          </CenteredColumn>
      }
    </div>
  );
};

export default compose(
  withState('selectedItems', 'setSelectedItems', []),
  withState('filter', 'setFilter', {}),
  withState('errors', 'setErrors', []),
  withState('componentkey', 'setKey', 0),
  withReducer('errors', 'dispatch', (state, action) => {
    switch (action.type) {
      case 'Add':
        return action.payload;
      default:
        return [];
    }
  }, []),
  withHandlers({
    setErrors: ({ dispatch, setKey }) => error => {
      dispatch({ type: 'Add', payload: error })
      setKey(Math.floor(Math.random() * 100))
    },
  }),
  connect(
    state => ({
        isDispatchBroker: isDispatchBroker(state),
        useNewNFRequest: get(state, `user.factoring.data.use_new_non_factored_funding_requests`, false),
        enableHoldReviewFundingRequestStatus: get(state, `user.factoring.data.enable_hold_review_funding_request_status`, false),
    }),
    {
      fetchFactoringProfile
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchFactoringProfile();
    }
  }),
  shouldUpdate((props, nextProps) => !(
    shallowEqual(props, nextProps)
  )))(DebtorTransactionContainer);
