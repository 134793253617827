/* global EMPTY_PLACEHOLDER */
import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, reduxForm } from 'redux-form';
import cs from 'classnames';
import { mapProps, withState, lifecycle } from 'recompose';
import { get, pick, filter, isEmpty, assign, cloneDeep } from 'lodash';
import { USER_TYPE, mapColumnsToBlockRows } from 'helpers';
import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';
import getSelfType from 'selectors/getSelfType';
import selectBrokerGroups from 'selectors/selectBrokerGroups';

import formatPennies from 'helpers/formatPennies';
import formatAddress from 'helpers/formatAddress';
import { openModal } from 'actions/ui';
import { edit, fetchCollated, fetchInvoice } from 'actions/resource/factoringpayment';
import SubmissionError from 'datatypes/error/SubmissionError';
import formatCategoriesAttachments from 'helpers/formatCategoriesAttachments';
import groups from 'actions/admin/factoring/groups';

import Resource from 'components/hoc/Resource';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import TableItem from 'components/pure/TableItem';
import BlockTable from 'components/pure/BlockTable';
import Header from 'components/pure/Header';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import FactoringPaymentAttachments from 'components/pure/FactoringPaymentAttachments';
import Spinner from 'components/pure/Spinner';
import Drawer from 'components/pure/Drawer';
import { Button } from 'react-bootstrap';
import { getLineItemsByFundingRequest } from 'actions/lineitems';
import moment, { DetailRow, groupLineItemsByCategories, SmallDetailRow, Title } from '../../FactoringPayment';

const styles = {
  flexContainer: {
    display: 'flex',
    // marginTop: 10,
    marginBottom: 5,
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  labelStyle: {
    fontSize: '14',
    fontTransform: 'capitalize !important',
  },
};

const formatStatus = status => typeof status === 'string' ? status.replace(/_/g, ' ') : status;


const TotalPayTitle = ({ status, amount, amount_funded, color }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }}
    >
    <div
      style={{
        display: 'flex',
      }}
      >
      <h3>Total Pay: {amount_funded}</h3>
      <h3
        style={{
          // color: color,
          marginLeft: '16px',
          textTransform: 'capitalize',
        }}
        className={cs('text-uppercase remove_underscore', {
          'text-success': ['approved', 'paid'].includes(status),
          'text-warning': ['pending', 'pending_originals', 'pending originals', 'pending_noa', 'hold'].includes(status),
          'text-red': ['declined', 'incomplete', 'pending_delivery', 'document_issue'].includes(status),
          'text-blue': ['new', 'fuel_advance', 'non_factored'].includes(status),
        })}
        >
        {status === 'pending_delivery' ? 'incomplete' : formatStatus(status)}
      </h3>
    </div>
    <div
      style={{
        // minWidth: '150px',
        justifySelf: 'flex-end',
      }}
      >
      <h3>{amount}</h3>
    </div>
  </div>
);

const statusColor = status => {
  switch (status) {
    case 'new':
      return 'green';
    case 'paid':
      return 'green';
    default:
      return 'black';
  }
};
const getCategories = item => {
  const paymentRequestAttachments = get(item, 'attachments', []);
  let categories = [];
  if (!isEmpty(paymentRequestAttachments)) {
    paymentRequestAttachments.forEach(element => {
      if (element.category && !Array.isArray(element.category)) {
        element.category.split(',').forEach(category => {
          categories = [...categories, { name: category }];
        });
      }
      else if (Array.isArray(element.category) && element.category.length) {
        element.category.forEach(category => {
          categories = [...categories, { name: category }];
        });
      }
    });
    return categories;
  }
  return [];
};

const FactoringPaymentTableClient = ({
  item,
  handleSubmit,
  submitting,
  fetchInvoice,
  fetchCollated,
  table,
  updateFactoringPayment,
  disableAll,
  paymentRequestStatusNotes,
  openAttachmentErrorModal,
  givebackchargeback,
  otherfees,
  fueladvance,
  deliveryadvance,
}) => {
  return (
    <div
      className='col-xs-12'
      style={{
        marginBottom: '160px',
        // padding: 10,
      }}
      >
      <Drawer
        title={
          <div>
            <div className='col-xs-12' style={styles.flexContainer}>
              <TotalPayTitle
                status={item.data.status}
                amount={formatPennies(item.data.amount)}
                amount_funded={formatPennies(item.data.amount_funded)}
                color={statusColor(item.data.status)}
              />
            </div>
          </div>
        }
        >
        <div className='col-xs-12'>
          <Header>Payment Details</Header>
          {table}
          { paymentRequestStatusNotes &&
          <div
            className={cs('alert', {
              'alert-success': ['approved', 'remote_approved', 'paid'].includes(item.data.status),
              'alert-warning': ['pending'].includes(item.data.status),
              'alert-danger': ['declined', 'document_issue', 'incomplete', 'pending_delivery'].includes(item.data.status),
            })}
            style={{ padding: '15px 15px', fontSize: 15, marginTop: 20 }}
            >
            <span style={{ color: '#333333', fontSize: '1.26rem;', fontWeight: 'bold', marginRight: 10 }}>
              Payment Request Status Notes:
            </span>
            {paymentRequestStatusNotes}
          </div>
          }
        </div>
      </Drawer>
      {givebackchargeback && (<Drawer title={<Title name='Givebacks/Chargebacks' amount={formatPennies(givebackchargeback.reduce((acc, curr) => acc + curr.amount_approved, 0))} amount_color={givebackchargeback.reduce((acc, curr) => acc + curr.amount_approved, 0) > 0 ? 'green' : 'red'} />}>
        <div>
          {givebackchargeback.map(charge => (
            <DetailRow
              name='Invoice Number'
              name_value={get(charge, 'related_line_item_data.funding_request_invoice_number', '') ? get(charge, 'related_line_item_data.funding_request_invoice_number', {}) : charge.funding_request_invoice_number}
              value={formatPennies(charge.amount_approved)}
              description={get(charge, 'related_line_item_data.description', '')}
              value_color={charge.amount_approved >= 0 ? 'green' : 'red'}
              categoryName={get(charge, 'related_line_item_data.category_name', '')}
            />
          ))}
        </div>
      </Drawer>)}
      {otherfees && (<Drawer
        title={
          <Title name='Other Fees' amount={formatPennies(otherfees.reduce((acc, curr) => acc + curr.amount_approved, 0))} amount_color={otherfees.reduce((acc, curr) => acc + curr.amount_approved, 0) >= 0 ? 'green' : 'red'} />
        }
        >
        <div>
          {otherfees.map(fee => (
            <DetailRow
              name={fee.category_name}
              value={formatPennies(fee.amount_approved)}
              value_color={fee.amount_approved >= 0 ? 'green' : 'red'}
            />
          ))}
        </div>
      </Drawer>)}
      <Drawer
        title={
          <Title name='Total Pay' amount={formatPennies(item.data.amount_funded)} amount_color={'black'} />
        }
        >
        <div>
          {fueladvance && (
            <div>
              <div
                style={{
                  marginLeft: '64px',
                  marginRight: '64px',
                }}
                >
                <Title name='Fuel Advance Details' amount={fueladvance.amount_requested} amount_color='black' />
              </div>
              {fueladvance.map(advance => (
                <div>
                  <DetailRow
                    name='Amount Requested'
                    value={formatPennies(advance.amount_requested)}
                    value_color='green'
                  />
                  {/*<DetailRow*/}
                  {/*  name='Fuel Advance Fee'*/}
                  {/*  value={`-${formatPennies(advance.amount_approved_fee)}`}*/}
                  {/*  value_color='red'*/}
                  {/*/>*/}
                  { advance.payment_fuel_advance_fee &&
                  <DetailRow
                    name='Payment Fee'
                    value={`${formatPennies(advance.payment_fuel_advance_fee)}`}
                    value_color='red'
                  />
                  }
                  <DetailRow
                    name='Paid At'
                    value={moment(advance.created).format('YYYY/MM/DD HH:SS a')}
                  />
                </div>
              ))}
            </div>
          )}
          {deliveryadvance && (
            <div>
              <div
                style={{
                  marginLeft: '64px',
                  marginRight: '64px',
                }}
                >
                <Title
                  name='Delivery Payment' amount={''} amount_color={''}
                />
              </div>
              <div>
                {deliveryadvance.map(advance => (
                  <div>
                    {
                      Boolean(item.data.discount_rate) &&
                      <SmallDetailRow
                        name='Discount Rate Fee'
                        value={`-${formatPennies(item.data.amount * (item.data.discount_rate / 100))}`}
                        value_color='red'
                      />
                    }
                    { Boolean(advance.payment_fee_amount_requested) &&
                    <SmallDetailRow
                      name='Payment Fee'
                      value={`-${formatPennies(advance.payment_fee_amount_requested)}`}
                      value_color='red'
                    />
                    }
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Drawer>
      <div
        className='col-xs-12'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '32px',
        }}
        >
        <div
          style={{
            color: '#0079b4',
          }}
          >
          <h4>
            Uploaded Documents
          </h4>
        </div>
        <div className='text-right'>
          <div>
            <FactoringPaymentAttachments
              label=''
              attachments={item.data.attachments}
              fetchInvoice={() => fetchInvoice(item.data.id, { document: 'invoice_payment_profile' })}
            />
            <div style={{ height: 30 }} />
            {
              ['pending_delivery', 'pending', 'document_issue'].includes(item.data.status) &&
              <Field
                name='attachments'
                component={MultiImageInput}
                limit={Infinity}
                buttonText='Upload Documents'
                buttonClassName='btn btn-default'
                disabled={disableAll}
                multiple={true}
                shouldCrop={true}
                categories={[
                  'Signed Bill of Lading (Delivered)',
                  'Signed Bill of Lading (Pick-Up)',
                  'Rate Confirmation',
                  'Truck Order Not Used',
                  'Invoice',
                  'Freight Bill',
                  'Fuel Advance',
                  'Lumper Receipt',
                  'Detention Receipt',
                  'Late Fee Receipt',
                  'Other',
                ]}
                imageGridProps={{
                  columns: {
                    sm: 2,
                    md: 2,
                    lg: 2,
                  },
                }}
              />
            }
          </div>
        </div>
      </div>
      <div className='col-xs-12' style={{ ...styles.flexContainer, ...styles.alignRight, marginTop: 10 }}>
        {
          item.data.status === 'pending_delivery' &&
          <Button
            className='btn btn-green pull-right'
            style={{
              minWidth: 160,
            }}
            type='submit'
            onClick={handleSubmit}
            disabled={submitting}
            >
            Mark As Complete
          </Button>
        }
        {
          ['pending', 'document_issue'].includes(item.data.status) &&
          <Button
            style={{
              minWidth: 160,
            }}
            className='btn btn-orange pull-right'
            type='submit'
            onClick={handleSubmit}
            disabled={submitting}
            >
            Update
          </Button>
        }
      </div>
    </div>
  );
};

const FactoringPaymentBlockTableItemClient = () => (({ headers, data }) => {
  return (
    <TableItem
      rows={mapColumnsToBlockRows(headers,
        [
          formatPennies(data.amount),
          data.invoice_number,
          data.user_load_number,
          formatAddress(data.first_origin_location),
          formatAddress(data.final_destination_location),
          data.load_length,
          data.load_trailer_type,
          data.bill_to_company_name,
          formatAddress(data.bill_to_address),
          <a href={`tel:${data.bill_to_company_phone}`}>{data.bill_to_company_phone}</a>,
          data.created.format('l LTS'),
          data.funded_at ? data.funded_at.format('l LTS') : EMPTY_PLACEHOLDER,
          data.discount_rate ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
          data.payment_method ? data.payment_method.toUpperCase() : EMPTY_PLACEHOLDER,
        ]
      )}
    />
  );
});

export default compose(
  Resource('factoringpayment', {
    idPropName: ['params', 'id'],
  }),
  spinnerIfFetching(),
  mapProps(props => {
    const data = get(props, 'item.data', {});
    const status = get(data, 'status', '');
    return {
      ...props,
      disableAll: status === 'paid' || status === 'approved',
      disablePayment: !(status === 'new' || status === 'pending'),
      form: `FactoringPaymentEdit#${data.id}`,
      initialValues: {
        ...pick(data, [
          'amount',
          'invoice_number',
          'user_load_number',
          'first_origin_location',
          'final_destination_location',
          'load_length',
          'load_trailer_type',
          'debtor',
          'bill_to_address',
          'payment_method',
          'carrier_amount',
          'bill_to_company_name',
        ]),
        bill_to_company_phone: getPhoneParts(data.bill_to_company_phone).phone,
        bill_to_company_phone_ext: getPhoneParts(data.bill_to_company_phone).ext,
        factoring: data.factoring_id,
        carrier_name: get(data, 'factoring_company_profile.name', ''),
        debtor_company_name: { dba: data.debtor_company_name },
      },
    };
  }),
  connect(
    state => ({
      loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
      userType: getSelfType(state),
    }),
    (dispatch, { form, item: { data: { id, ...data } } }) => ({
      fetchInvoice(...args) {
        try {
          return dispatch(fetchInvoice(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch invoice at this time. Please try again later.' }));
        }
      },
      fetchCollated(...args) {
        try {
          return dispatch(fetchCollated(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch pdf at this time. Please try again later.' }));
        }
      },
      setForm: (name, value) => dispatch(change(form, name, value)),
      async onSubmit(fields, dispatch) {
        let newFields = cloneDeep(fields);
        if (data.status === 'pending_delivery') {
          const categories = [...getCategories(data), ...getCategories(fields)];
          if (!filter(
            categories,
            object => object.name === 'Signed Bill of Lading (Delivered)'
          ).length) {
            return dispatch(openModal('error', { message: 'Please make sure to add the following document: ' +
                '\n - Signed Bill of Lading (Delivered)' }));
          }
        }
        let payload = {};
        if (data.status === 'pending_delivery') {
          payload = { status: 'special_pending' };
        }
        if (fields.attachments.length) {
          newFields = assign(newFields, { attachments: formatCategoriesAttachments(newFields.attachments) });
        }

        return dispatch(edit(id, {
          ...pick(newFields, [
            'attachments',
          ]),
          ...payload,
        }))
          .then(() => {
            dispatch(openModal('success', { message: 'Successfully edited transaction' }));
          })
          .catch(err => {
            console.warn(err);
            if (get(err, 'message.detail', '')) {
              return dispatch(openModal('error', {
                message: get(err, 'message.detail', ''),
              }));
            }
            dispatch(openModal('error', { message: 'Error editing transaction' }));
          })
        ;
      },
      getLineItemsByFundingRequest: id => dispatch(getLineItemsByFundingRequest(id)),
      deleteAttachment(id, attachment) {
        return dispatch(
          openModal('confirm', {
            type: 'factoringpaymentattachment_delete',
            args: [id, attachment],
          })
        );
      },
    })
  ),
  withState('lineItems', 'setLineItems', []),
  lifecycle({
    async componentDidMount() {
      const lineItems = await this.props.getLineItemsByFundingRequest(this.props.params.id);
      this.props.setLineItems(lineItems);
    },
  }),
  reduxForm({}),
)(({ disableAll, item, loading, handleSubmit, submitting, fetchInvoice, fetchCollated, updateFactoringPayment, openAttachmentErrorModal, lineItems }) => {
  const grouped = groupLineItemsByCategories(lineItems);
  const fueladvance = grouped['Fuel Advance Purchase'];
  const givebackchargeback = grouped['Givebacks/Chargesbacks'];
  const otherFees = grouped.Charges;
  const deliveryadvance = grouped['Delivery Advance'];
  return (
    <div className='row'>
      {
        loading ?
          <Spinner />
          :
          <FactoringPaymentTableClient
            disableAll={disableAll}
            item={item}
            handleSubmit={handleSubmit}
            submitting={submitting}
            fetchInvoice={fetchInvoice}
            fetchCollated={fetchCollated}
            updateFactoringPayment={updateFactoringPayment}
            openAttachmentErrorModal={openAttachmentErrorModal}
            paymentRequestStatusNotes={get(item, 'data.status_note', '')}
            fueladvance={fueladvance}
            givebackchargeback={givebackchargeback}
            otherfees={otherFees}
            deliveryadvance={deliveryadvance}
            table={<BlockTable
              headers={[
                'Invoice Amt.',
                'Your Invoice #',
                'Your Load #',
                'First Pick Up',
                'Final Delivery',
                'Length',
                'Type',
                'Bill To',
                'Bill To Company Address',
                'Bill to Company Phone',
                'Requested At',
                'Funded At',
                'Rate',
                'Payment Method',
              ]}
              items={[item]}
              ListItem={FactoringPaymentBlockTableItemClient()}
            />}
          />
      }
    </div>
  );
});
